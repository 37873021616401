import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"sartlar-ve-kosullar"} />
		<Helmet>
			<title>
				EventExcellence
			</title>
			<meta name={"description"} content={"Güvenilir Etkinlik Yönetimi Ortağınız"} />
			<meta property={"og:title"} content={"EventExcellence"} />
			<meta property={"og:description"} content={"Güvenilir Etkinlik Yönetimi Ortağınız"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
		</Helmet>
		<Components.Header1 />
		<Section padding="80px 0 0 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Şartlar ve Koşullar
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Bu Şartlar ve Koşullar, Türkiye'deki EventExcellence etkinlik ajansının hizmetlerinden yararlanan tüm müşteriler için geçerlidir. Lütfen hizmetlerimizi kullanmadan önce bu şartları dikkatlice okuyun.
			</Text>
		</Section>
		<Section padding="40px 0" sm-padding="20px 0">
			<Override slot="SectionContent" align-items="flex-start" />
			<Text as="h3" font="--headline3" margin="20px 0">
				1. Hizmet Tanımı
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				EventExcellence, etkinlik planlama, koordinasyon ve yönetim hizmetleri sunmaktadır. Bu hizmetler, kurumsal etkinliklerden düğünlere, özel partilerden büyük çaplı organizasyonlara kadar geniş bir yelpazeyi kapsamaktadır. Hizmetlerimiz, müşterinin talepleri doğrultusunda kişiselleştirilir.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				2. Sözleşme ve Ödeme
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				EventExcellence ile bir etkinlik için anlaşma yapılmadan önce, müşteri ile hizmet detayları, ücretler ve ödeme koşulları hakkında karşılıklı olarak anlaşılmalıdır. Tüm ödemeler, hizmet başlamadan önce veya belirlenen ödeme planına göre yapılmalıdır.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				3. İptal ve Değişiklikler
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Etkinlik tarihinde veya hizmet detaylarında herhangi bir değişiklik yapılması durumunda, bu değişiklikler EventExcellence’a yazılı olarak bildirilmelidir. Müşteri tarafından yapılan iptaller, sözleşmede belirtilen iptal şartlarına tabi olacaktır. İptal durumunda, belirli bir süre içinde yapılmayan iptallerde ücretin tamamı veya bir kısmı tahsil edilebilir.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				4. Sorumluluk ve Garanti
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				EventExcellence, hizmetlerin yerine getirilmesinde gerekli özeni gösterecektir. Ancak, mücbir sebepler veya kontrolümüz dışındaki diğer durumlar nedeniyle ortaya çıkabilecek gecikmeler veya aksaklıklardan dolayı sorumluluk kabul edilmez. Müşteri, etkinlik süresince kendi güvenliğinden ve kişisel eşyalarından sorumludur.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				5. Gizlilik ve Veri Koruma
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				EventExcellence, müşteri bilgilerini gizli tutmayı ve üçüncü şahıslarla paylaşmamayı taahhüt eder. Kişisel veriler, yalnızca hizmetlerin yerine getirilmesi amacıyla kullanılacak ve ilgili mevzuata uygun olarak korunacaktır.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				6. İletişim
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Herhangi bir sorunuz veya daha fazla bilgi almak için lütfen info@eventexcellence.com adresinden bizimle iletişime geçin.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				7. Uygulanacak Hukuk ve Yetki
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Bu Şartlar ve Koşullar, Türkiye Cumhuriyeti kanunlarına tabi olacaktır. Herhangi bir anlaşmazlık durumunda, İstanbul mahkemeleri ve icra daireleri yetkili olacaktır.
			</Text>
		</Section>
		<Components.Contact />
		<Components.NewFooter1 />
		<Components.Terms>
			<Override slot="link1" />
		</Components.Terms>
	</Theme>;
});